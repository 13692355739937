<template>
	<div>
		<v-card v-if="false" width="100%">
			<apexchart type="rangeBar" width="100%" height="800" :options="chartOptions" :series="series"></apexchart>
		</v-card>
		<br />
		<v-btn-toggle dense mandatory v-model="app" @change="setAppFilter">
			<v-btn style="font-size: 0.6em" :value="null">Tudo</v-btn>
			<v-btn style="font-size: 0.6em" value="tipy">Coelhinho</v-btn>
			<v-btn style="font-size: 0.6em" value="agenda">Butler</v-btn>
		</v-btn-toggle>
		<br /><br />
		<v-data-table
			:headers="headers"
			:items="sessions"
			:search="search"
			:loading="loading"
			:items-per-page="perPage"
			:server-items-length="total"
			item-key="_id"
			must-sort
			no-data-text="Não há resultados"
			no-results-text="Não há resultados"
			:footer-props="{
				itemsPerPageOptions: [10, 20],
				itemsPerPageText: 'Linhas',
				showCurrentPage: true,
				showFirstLastPage: true,
			}"
			@update:options="setOptions"
		>
			<template v-slot:top>
				<v-toolbar>
					<v-toolbar-title>Sessões</v-toolbar-title>
					<v-spacer />
					<v-text-field
						outlined
						dense
						v-model="search"
						append-icon="mdi-magnify"
						label="Procurar"
						style="margin: 0px 10px; max-width: 300px"
						hide-details
						@keyup="setSearch"
					/>
				</v-toolbar>
			</template>
			<template v-slot:item.total="{ item }">{{ toHoursAndMinutes(item.total) }}</template>
			<template v-slot:item.average="{ item }">{{ toHoursAndMinutes(item.average) }}</template>
			<template v-slot:item.dailyAverage="{ item }">{{ toHoursAndMinutes(item.dailyAverage) }}</template>
			<template v-slot:item.lastSession="{ item }">
				{{ formatDate(item.lastSession, "DD MMM YYYY HH:mm") }}
			</template>
		</v-data-table>
	</div>
</template>
<script>
import Vue from "vue";
import dayjs from "dayjs";
import { mapState } from "vuex";

import { getSessions } from "../../api/sessions";
import { formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "Sessions",
	components: {},
	created() {
		this.handleGetSessions();
	},
	watch: {
		"$vuetify.theme.dark": function (newVal) {
			this.setColors(newVal);
		},
		selectedOffices: function () {
			this.handleGetSessions();
		},
		dateRange: function () {
			this.handleGetSessions();
		},
	},
	computed: mapState(["selectedOffices", "dateRange"]),
	data() {
		return {
			loading: false,
			search: "",
			page: 0,
			perPage: 10,
			sortBy: "total",
			sortDesc: true,

			sessions: [],
			total: 0,

			app: null,

			headers: [
				{ text: "Nome", value: "user.name", width: 200 },
				{ text: "Total", value: "total", width: 150 },
				{ text: "Número de Sessões", value: "count", width: 175 },
				{ text: "Sessão Média", value: "average", width: 150 },
				{ text: "Média Diária", value: "dailyAverage", width: 150 },
				{ text: "Última Sessão", value: "lastSession" },
			],

			series: [],
			chartOptions: {
				chart: {
					toolbar: {
						show: false,
					},
				},
				colors: ["#2E93fA", "#ffcc00"],
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						style: {
							colors: "#ffffff",
						},
					},
				},
				yaxis: {
					labels: {
						style: {
							colors: "#ffffff",
						},
					},
				},
				legend: {
					show: true,
					showForSingleSeries: true,
					labels: {
						colors: "#ffffff",
					},
				},
				tooltip: {
					theme: "dark",
					x: {
						format: "dd/MM/yy HH:mm",
					},
				},
				noData: {
					text: "Não foram encontradas sessões",
				},
			},
		};
	},
	methods: {
		formatDate,
		setOptions(options) {
			if (
				this.perPage !== options.itemsPerPage ||
				this.sortBy !== options.sortBy ||
				this.sortDesc !== options.sortDesc
			) {
				this.page = 0;
			}

			this.page = options.page - 1;
			this.perPage = options.itemsPerPage;
			this.sortBy = options.sortBy;
			this.sortDesc = options.sortDesc;

			this.handleGetSessions();
		},
		setSearch(e) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.search = e.target.value;
				this.page = 0;
				this.handleGetSessions();
			}, 800);
		},
		setAppFilter(value) {
			this.page = 0;

			this.app = value;

			this.handleGetSessions();
		},
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				xaxis: { type: "datetime", labels: { style: { colors: textColor } } },
				yaxis: { labels: { style: { colors: textColor } } },
				legend: { ...this.chartOptions.legend, labels: { colors: textColor } },
				tooltip: { ...this.chartOptions.tooltip, theme: darkMode ? "dark" : "light" },
			};
		},
		toHoursAndMinutes(totalMinutes) {
			const hours = Math.floor(totalMinutes / 60);
			const minutes = totalMinutes % 60;

			let string = "";

			if (hours) string += `${hours}h `;
			string += `${minutes.toFixed(0)}min`;

			return string;
		},
		async handleGetSessions() {
			this.loading = true;

			const response = await getSessions(
				this.page,
				this.perPage,
				this.sortBy,
				this.sortDesc,
				this.app,
				this.search,
			);

			if (response.status === 200) {
				const data = [];
				const butlerData = [];

				this.sessions = response.data.sessions;
				this.total = response.data.total;

				for (const user of response.data.sessions) {
					for (const session of user.sessions) {
						const s = {
							x: user.user.name,
							y: [
								dayjs(session.startDate).valueOf(),
								session.endDate ? dayjs(session.endDate).valueOf() : dayjs().valueOf(),
							],
						};

						if (session.app === "agenda") {
							butlerData.push(s);
						} else {
							data.push(s);
						}
					}
				}

				this.series = [
					{ name: "Coelhinho", data },
					{ name: "Butler", data: butlerData },
				];
			}

			this.loading = false;
		},
	},
});
</script>
