<template>
	<div style="display: flex; flex-direction: column; justify-content: center; gap: 10px">
		<div>
			<v-btn-toggle mandatory v-model="groupBy" style="margin-bottom: 10px">
				<v-btn value="consultant">Individual</v-btn>
				<v-btn value="team">Equipas</v-btn>
			</v-btn-toggle>
			<v-btn-toggle mandatory v-model="fromAllOffices" style="margin-left: 10px">
				<v-btn :value="false">Agência</v-btn>
				<v-btn :value="true">Grupo</v-btn>
			</v-btn-toggle>
		</div>
		<div v-if="loading" :style="`display: flex; gap: 10px; justify-content: center; margin:${150}px`">
			<v-skeleton-loader v-for="card in cards" :key="card.stat" min-width="450px" type="image" />
		</div>
		<div v-else v-for="period in periods.slice(0, -1)" :key="period.key">
			<h2>{{ period.name }}</h2>
			<div style="display: flex; justify-content: center; gap: 10px">
				<div v-for="card in cards" :key="card.stat">
					<v-data-table
						v-if="statsByType[period.key]"
						:headers="[
							{ text: 'Nome', value: 'name', width: 170 },
							{
								text: `${card.name}${card.stat === 'findings' ? '' : ' (Diferencial em %)'}`,
								value: 'stat',
								width: 190,
								align: 'right',
							},
						]"
						:items="statsByType[period.key][card.stat]"
						:loading="loading"
						item-key="_id"
						must-sort
						no-data-text="Não há resultados"
						no-results-text="Não há resultados"
						:footer-props="{
							itemsPerPageOptions: [10, 20],
							itemsPerPageText: 'Linhas',
							showCurrentPage: true,
							showFirstLastPage: true,
						}"
					>
						<template v-slot:item.stat="{ item }">
							{{
								card.stat === "findings"
									? item[period.key][card.stat]
									: features.listBillingStats
									? formatNumber(item[period.key][card.stat], true)
									: item[period.key][`${card.stat}ComparedToRankAbovePercent`]
									? `${formatNumber(item[period.key][`${card.stat}ComparedToRankAbovePercent`])}%`
									: ""
							}}
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import { getGroupedStats } from "../../api/stats";

import { formatNumber, formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "RankingTables",
	created() {
		this.handleGetStats();
	},
	watch: {
		dateRange: function () {
			this.formatStats();
		},
		stat: function () {
			this.formatStats();
		},
		groupBy: function () {
			this.handleGetStats();
		},
		fromAllOffices: function () {
			this.handleGetStats();
		},
		allOffices: function () {
			this.handleGetStats();
		},
	},
	computed: mapState(["allOffices", "dateRange", "user", "features"]),
	data() {
		return {
			loading: false,

			cards: [
				{ name: "Angariações", stat: "findings", icon: "mdi-home-automation" },
				{ name: "Pipeline", stat: "reservations", icon: "mdi-bookmark" },
				{ name: "Faturação", stat: "billing", icon: "mdi-file-check" },
			],
			periods: [],

			stats: null,
			allStats: [],
			statsByType: {},

			groupBy: "consultant",
			fromAllOffices: false,
		};
	},
	methods: {
		formatNumber,
		async handleGetStats() {
			this.loading = true;

			const response = await getGroupedStats(
				this.groupBy,
				"billing",
				null,
				null,
				null,
				"!team",
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				this.fromAllOffices ? this.allOffices.map(o => o.key) : null,
			);

			if (response.status === 200) {
				this.allStats = response.data;

				this.formatStats();
			} else {
				this.$router.replace("/users");
			}

			this.loading = false;
		},
		async formatStats() {
			this.today = dayjs(this.dateRange[1]);
			this.year = this.today.get("year");

			this.periods = [
				{ name: formatDate(this.today, "MMM YYYY"), key: `${this.today.get("month") + 1}-${this.year}` },
				{ name: this.year, key: this.year.toString() },
			];

			if (this.year === dayjs(this.dateRange[0]).get("year")) {
				this.periods.push({ name: "Intervalo selecionado", key: "period" });
			}

			this.statsByType = {};
			for (const period of this.periods.slice(0, -1)) {
				this.statsByType[period.key] = {};

				for (const card of this.cards) {
					let sortedStats = [];

					for (const id in this.allStats) {
						if (id !== "total" && this.allStats[id] && this.allStats[id][period.key]) {
							sortedStats.push(this.allStats[id]);
						}
					}

					sortedStats.sort(
						(a, b) =>
							(b[period.key] ? b[period.key][card.stat] || 0 : 0) -
							(a[period.key] ? a[period.key][card.stat] || 0 : 0),
					);

					if (this.groupBy === "consultant") sortedStats = sortedStats.filter(s => !s.team);

					this.statsByType[period.key][card.stat] = sortedStats;

					for (let i = 0; i < this.statsByType[period.key][card.stat].length; i++) {
						const consultant = this.statsByType[period.key][card.stat][i];
						const previousConsultant = i > 0 ? this.statsByType[period.key][card.stat][i - 1] : null;

						if (consultant[period.key] && consultant[period.key][card.stat] && previousConsultant) {
							consultant[period.key][`${card.stat}ComparedToRankAbove`] =
								consultant[period.key][card.stat] - previousConsultant[period.key][card.stat];
							consultant[period.key][`${card.stat}ComparedToRankAbovePercent`] =
								(consultant[period.key][`${card.stat}ComparedToRankAbove`] / consultant[period.key][card.stat]) *
								100;
						}
					}
				}
			}
		},
	},
});
</script>
