import { api } from "../utils/request";

async function getReservations(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/reservations/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function getReservationsFilters() {
	const res = await api({
		method: "get",
		url: "/api/reservations/filters",
	});

	return res;
}

async function addReservation(reservation) {
	const res = await api({
		method: "post",
		url: "/api/reservations",
		data: reservation,
	});

	return res;
}

async function editReservation(reservation) {
	const res = await api({
		method: "put",
		url: `/api/reservations/${reservation._id}`,
		data: reservation,
	});

	return res;
}

async function patchReservation(reservation) {
	const res = await api({
		method: "patch",
		url: `/api/reservations/${reservation._id}`,
		data: reservation,
	});

	return res;
}

async function deleteReservation(id) {
	const res = await api({
		method: "delete",
		url: `/api/reservations/${id}`,
	});

	return res;
}

export {
	getReservations,
	getReservationsFilters,
	addReservation,
	editReservation,
	patchReservation,
	deleteReservation,
};
