<template>
	<v-tooltip :disabled="!tooltip" top color="#222">
		<template v-slot:activator="{ on, attrs }">
			<v-chip
				v-if="chip"
				v-bind="attrs"
				v-on="on"
				small
				:color="getIconParameters(isValidNumber() ? stats[`${stat}${translateStatsType()}`] : undefined).color"
				@click="onClick"
			>
				{{
					isValidNumber()
						? `${formatNumber(stats[`${stat}${translateStatsType()}`], currency, false, 0, true)}${
								type ? "" : "%"
						  }`
						: "N/A"
				}}
				<v-icon right small>
					{{ getIconParameters(isValidNumber() ? stats[`${stat}${translateStatsType()}`] : undefined).icon }}
				</v-icon>
			</v-chip>
			<span v-else v-bind="attrs" v-on="on" @click="onClick" style="cursor: pointer">
				<span
					:class="`${
						getIconParameters(isValidNumber() ? stats[`${stat}${translateStatsType()}`] : undefined).color
					}--text`"
				>
					{{
						isValidNumber()
							? `${formatNumber(stats[`${stat}${translateStatsType()}`], currency, false, 0, true)}${
									(absoluteFirst ? !type : type) || !isValidNumber() ? "" : "%"
							  }`
							: ""
					}}
				</span>
				<v-icon
					v-if="isValidNumber()"
					small
					:color="getIconParameters(isValidNumber() ? stats[`${stat}${translateStatsType()}`] : undefined).color"
				>
					{{ getIconParameters(isValidNumber() ? stats[`${stat}${translateStatsType()}`] : undefined).icon }}
				</v-icon>
			</span>
		</template>
		<span>{{ tooltip }}</span>
	</v-tooltip>
</template>

<script>
import Vue from "vue";

import { formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "PercentageChip",
	props: ["stats", "stat", "tooltip", "chip", "absoluteFirst", "currency", "type", "onClick"],
	methods: {
		formatNumber,
		translateStatsType() {
			return this.type === 2
				? "Last"
				: this.type === 1
				? this.absoluteFirst
					? "Percent"
					: ""
				: this.absoluteFirst
				? ""
				: "Percent";
		},
		isValidNumber() {
			return (
				this.stats &&
				this.stats[`${this.stat}${this.translateStatsType()}`] !== null &&
				this.stats[`${this.stat}${this.translateStatsType()}`] !== undefined
			);
		},
		getIconParameters(percentDiff) {
			return this.type === 2
				? { icon: "", color: "" }
				: percentDiff === undefined
				? { icon: "mdi-null", color: "grey" }
				: percentDiff === 0
				? { icon: "mdi-equal", color: "primary" }
				: percentDiff > 0
				? { icon: "mdi-arrow-up", color: "success" }
				: percentDiff > -20
				? { icon: "mdi-arrow-down", color: "primary" }
				: { icon: "mdi-arrow-down", color: "error" };
		},
	},
});
</script>
