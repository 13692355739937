<template>
	<v-app :style="`background-color: ${$vuetify.theme.dark ? '' : '#f2f2f2'}`">
		<v-app-bar v-if="token" app dense>
			<span v-if="horizontalMenu || smallScreen">
				<v-app-bar-nav-icon @click="showDrawer = !showDrawer" />
			</span>
			<v-tabs v-if="!horizontalMenu && !smallScreen">
				<v-tab
					v-if="
						features.listBillingStats ||
						features.listReferenceStats ||
						features.listFindingStats ||
						features.listLeadStats ||
						features.listUserStats ||
						features.listUserSessionStats
					"
					to="/"
				>
					<v-icon>mdi-home</v-icon>
				</v-tab>
				<v-tab v-if="features.listViabilities" to="/viabilities"><span class="tab">Viabilidades</span></v-tab>
				<v-tab v-if="features.listFindings" to="/findings"><span class="tab">Angariações</span></v-tab>
				<v-tab v-if="features.listReservations" to="/reservations"><span class="tab">Pipeline</span></v-tab>
				<v-tab v-if="features.listInvoices" to="/invoices"><span class="tab">Faturas</span></v-tab>
				<v-tab v-if="features.listExpenses" to="/expenses"><span class="tab">Despesas</span></v-tab>
				<v-tab v-if="features.listLeads" to="/leads"><span class="tab">Leads</span></v-tab>
				<v-tab v-if="features.listStaff" to="/users"><span class="tab">Staff</span></v-tab>
				<v-tab v-if="features.listConfigs" to="/config"><span class="tab">Config</span></v-tab>
			</v-tabs>
			<v-spacer />
			<span v-if="!horizontalMenu && !verySmallScreen" :style="`max-width: ${officesWidth}px`">
				<v-chip-group
					:value="selectedOfficesIndexes"
					multiple
					:show-arrows="smallScreen || offices.length * 115 < officesWidth"
				>
					<v-chip
						v-for="(office, index) in offices"
						:key="office.key"
						filter
						outlined
						dense
						:disabled="selectedOfficesIndexes.length === 1 && selectedOffices.includes(office.key)"
						:color="selectedOffices.includes(office.key) ? 'primary' : 'grey'"
						@click="() => selectOffices(office.key, index, selectedOffices.includes(office.key))"
					>
						{{ office.name }}
					</v-chip>
				</v-chip-group>
			</span>
			<v-menu
				v-if="!horizontalMenu"
				v-model="showDatePicker"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						outlined
						dense
						v-model="dateRangeText"
						label="Intervalo de Tempo"
						v-bind="attrs"
						v-on="on"
						hide-details
						style="min-width: 190px; max-width: 190px"
					/>
				</template>
				<v-card style="padding: 10px" align="center">
					<v-date-picker range v-model="dateRangeLocal" color="primary" />
					<v-select
						outlined
						dense
						v-model="dateRangeDefault"
						:items="dateRangeDefaults"
						item-text="name"
						item-value="value"
						label="Intervalos"
						hide-details
						style="max-width: 290px; padding-top: 10px"
					/>
				</v-card>
			</v-menu>
			<v-btn icon @click="changeTheme">
				<v-icon>{{ $vuetify.theme.dark ? "mdi-white-balance-incandescent" : "mdi-weather-night" }}</v-icon>
			</v-btn>
			<v-btn icon to="/profile">
				<v-icon>mdi-account</v-icon>
			</v-btn>
			<v-btn icon href="/logout">
				<v-icon>mdi-exit-to-app</v-icon>
			</v-btn>
		</v-app-bar>
		<div v-if="showDrawer" style="position: absolute; height: 100%">
			<div v-if="horizontalMenu || smallScreen" class="navigationDrawer">
				<v-navigation-drawer
					v-model="showDrawer"
					temporary
					hide-overlay
					style="position: relative; height: min-content"
				>
					<v-list nav>
						<v-list-item-group>
							<v-list-item
								v-if="
									features.listBillingStats ||
									features.listLeadStats ||
									features.listOfficeGoals ||
									features.listCommercialDirectorGoals ||
									features.listConsultantGoals ||
									features.listUserStats ||
									features.listUserSessionStats
								"
								to="/"
							>
								<v-list-item-icon>
									<v-icon>mdi-view-dashboard</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Dashboard</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listViabilities" to="/viabilities">
								<v-list-item-icon>
									<v-icon>mdi-bank</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Viabilidades</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listFindings" to="/findings">
								<v-list-item-icon>
									<v-icon>mdi-folder</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Angariações</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listReservations" to="/reservations">
								<v-list-item-icon>
									<v-icon>mdi-folder</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Pipeline</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listInvoices" to="/invoices">
								<v-list-item-icon>
									<v-icon>mdi-file-document</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Faturas</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listLeads" to="/leads">
								<v-list-item-icon>
									<v-icon>mdi-account-switch</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Leads</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listStaff" to="/users">
								<v-list-item-icon>
									<v-icon>mdi-account-star</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Staff</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="features.listConfigs" to="/config">
								<v-list-item-icon>
									<v-icon>mdi-wrench</v-icon>
								</v-list-item-icon>
								<v-list-item-title>Config</v-list-item-title>
							</v-list-item>
							<span>
								<v-select
									v-if="horizontalMenu || verySmallScreen"
									outlined
									dense
									:value="selectedOffices"
									:items="offices"
									item-text="name"
									item-value="key"
									multiple
									small-chips
									label="Agências"
									@change="changeOffices"
									style="z-index: 1001"
								/>
							</span>
							<v-menu
								v-if="horizontalMenu"
								v-model="showDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								style="z-index: 1001"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										dense
										v-model="dateRangeText"
										label="Intervalo de Tempo"
										v-bind="attrs"
										v-on="on"
										hide-details
									/>
								</template>
								<v-card style="padding: 10px" align="center">
									<v-date-picker range v-model="dateRangeLocal" color="primary" />
									<v-select
										outlined
										dense
										v-model="dateRangeDefault"
										:items="dateRangeDefaults"
										item-text="name"
										item-value="value"
										label="Intervalos"
										hide-details
										style="max-width: 290px; padding-top: 10px"
									/>
								</v-card>
							</v-menu>
						</v-list-item-group>
					</v-list>
				</v-navigation-drawer>
			</div>
		</div>
		<v-main>
			<router-view />
			<ToDo v-if="features.toDoWidget" />
			<v-dialog persistent v-model="needsPasswordChange" width="400px">
				<ChangePassword />
			</v-dialog>
			<v-snackbar right v-model="toast.open" :color="toast.color">
				{{ toast.message }}
				<template v-slot:action="{ attrs }">
					<v-btn text :color="toast.color ? 'white' : 'primary'" v-bind="attrs" @click="closeToast"> Close </v-btn>
				</template>
			</v-snackbar>
		</v-main>
	</v-app>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

import ToDo from "./components/ToDo";
import ChangePassword from "./components/ChangePassword";

import { getConfig, logout } from "./api/auth";
import { patchUser } from "./api/users";

import { formatDate } from "./utils/utils";

export default Vue.extend({
	name: "App",
	components: { ToDo, ChangePassword },
	created() {
		if (this.token) {
			this.handleGetConfig();

			this.dateRangeLocal = this.dateRange;
			this.setDateRangeDefault();

			this.setListeners();
		}
	},
	watch: {
		token: function () {
			if (this.token) {
				this.handleGetConfig();

				this.dateRangeLocal = this.dateRange;
				this.setDateRangeDefault();

				this.setListeners();
			}
		},
		offices: function (offices) {
			const indexes = [];
			for (let i = 0; i < offices.length; i++) {
				if (this.selectedOffices.includes(offices[i].key)) indexes.push(i);
			}

			this.selectedOfficesIndexes = indexes;
		},
		dateRangeLocal: function (newVal) {
			if (newVal !== this.dateRange && newVal.length === 2) {
				this.setDateRange(newVal);
				if (newVal !== this.dateRangeDefault) this.setDateRangeDefault();
			}
		},
		dateRangeDefault: function (newVal) {
			if (newVal !== this.dateRange && newVal.length === 2) this.dateRangeLocal = newVal;
		},
	},
	computed: {
		...mapState([
			"toast",
			"user",
			"token",
			"needsPasswordChange",
			"darkMode",
			"horizontalMenu",
			"dateRange",
			"dateRangeDefaults",
			"offices",
			"selectedOffices",
			"features",
		]),
		dateRangeText() {
			return this.dateRange.map(d => formatDate(d, "DD-MMM-YY")).join(" ~ ");
		},
	},
	data() {
		return {
			officesWidth: null,
			smallScreen: false,
			verySmallScreen: false,

			selectedOfficesIndexes: [],
			toggleOffice: true,

			showDatePicker: false,
			showDrawer: false,

			dateRangeLocal: [],
			dateRangeDefault: [],
		};
	},
	methods: {
		...mapMutations([
			"closeToast",
			"setDateRange",
			"setOldestDate",
			"setUser",
			"setDarkMode",
			"setHorizontalMenu",
			"setProfiles",
			"setOffices",
			"setSelectedOffices",
			"setFeatures",
			"setRedirect",
			"setConfig",
		]),
		setTopBarWidths() {
			let numberOfTabs = 0;

			if (
				this.features.listBillingStats ||
				this.features.listLeadStats ||
				this.features.listOfficeGoals ||
				this.features.listCommercialDirectorGoals ||
				this.features.listConsultantGoals ||
				this.features.listUserStats ||
				this.features.listUserSessionStats
			) {
				numberOfTabs++;
			}

			if (this.features.listInvoices) numberOfTabs++;
			if (this.features.listReservations) numberOfTabs++;
			if (this.features.listFindings) numberOfTabs++;
			if (this.features.listViabilities) numberOfTabs++;
			if (this.features.listExpenses) numberOfTabs++;
			if (this.features.listLeads) numberOfTabs++;
			if (this.features.listStaff) numberOfTabs++;
			if (this.features.listConfigs) numberOfTabs++;

			const tabsWidth = numberOfTabs * 110;
			this.officesWidth = window.innerWidth - tabsWidth - 300;

			if (this.officesWidth < 350) {
				this.smallScreen = true;
				this.verySmallScreen = this.officesWidth < 75;

				this.officesWidth = window.innerWidth - 400;
			} else {
				this.smallScreen = this.verySmallScreen = false;
			}
		},
		setListeners() {
			window.addEventListener("keydown", e => {
				if (
					/textarea|select|input/i.test(event.target.nodeName) ||
					["text", "password"].includes(event.target.type)
				) {
					return;
				}

				if (e.key === "CapsLock") {
					this.toggleOffice = e.getModifierState("CapsLock");
				} else if (e.key >= 0 && e.key <= 9) {
					const office = this.offices[e.key - 1];

					if (e.key === "0") {
						this.selectAllOffices(null, null, true);
					} else {
						this.selectOffice(office.key, e.key - 1, this.selectedOffices.includes(office.key));
					}
				} else if (
					e.key === "q" &&
					(this.features.listBillingStats ||
						this.features.listLeadStats ||
						this.features.listOfficeGoals ||
						this.features.listCommercialDirectorGoals ||
						this.features.listConsultantGoals ||
						this.features.listUserStats ||
						this.features.listUserSessionStats)
				) {
					this.$router.push("/");
				} else if (e.key === "w" && this.features.listInvoices) {
					this.$router.push("/invoices");
				} else if (e.key === "e" && this.features.listReservations) {
					this.$router.push("/reservations");
				} else if (e.key === "r" && this.features.listLeads) {
					this.$router.push("/leads");
				} else if (e.key === "t" && this.features.listStaff) {
					this.$router.push("/users" && this.features.listConfigs);
				} else if (e.key === "y" && this.features.listConfigs) {
					this.$router.push("/config");
				}
			});

			window.addEventListener("resize", this.setTopBarWidths);

			document.addEventListener("wheel", () => {
				if (document.activeElement.type === "number") {
					document.activeElement.blur();
				}
			});
		},
		async changeTheme() {
			this.setDarkMode(!this.$vuetify.theme.dark);
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

			await patchUser({ _id: this.user._id, darkMode: this.$vuetify.theme.dark });
		},
		setDateRangeDefault() {
			const isDateRangeDefault = this.dateRangeDefaults.find(
				d => d.value[0] === this.dateRangeLocal[0] && d.value[1] === this.dateRangeLocal[1],
			);

			this.dateRangeDefault = isDateRangeDefault ? isDateRangeDefault.value : [];
		},
		selectOffice(selectedOffice, index, active) {
			let officeKeys = this.selectedOffices;

			if (this.toggleOffice) {
				this.selectedOfficesIndexes = [index];
				officeKeys = [selectedOffice];
			} else {
				if (active) {
					this.selectedOfficesIndexes = this.selectedOfficesIndexes.filter(i => i !== index);
					officeKeys = officeKeys.filter(o => o !== selectedOffice);
				} else {
					this.selectedOfficesIndexes.push(index);
					officeKeys.push(selectedOffice);
				}
			}

			this.setSelectedOffices(officeKeys);
		},
		selectOffices(selectedOffice, index, active) {
			let officeKeys = this.selectedOffices;
			if (active) {
				this.selectedOfficesIndexes = this.selectedOfficesIndexes.filter(i => i !== index);
				officeKeys = officeKeys.filter(o => o !== selectedOffice);
			} else {
				this.selectedOfficesIndexes.push(index);
				officeKeys.push(selectedOffice);
			}

			this.setSelectedOffices(officeKeys);
		},
		selectAllOffices(selectedOffice, index, all) {
			let officeKeys = this.selectedOffices;
			if (all || this.selectedOffices.length === 1) {
				this.selectedOfficesIndexes = this.offices.map((o, i) => i);
				officeKeys = this.offices.map(o => o.key);
			} else if (this.selectedOffices.length > 1) {
				this.selectedOfficesIndexes = [index];
				officeKeys = [selectedOffice];
			}

			this.setSelectedOffices(officeKeys);
		},
		changeOffices(selectedOffices) {
			this.selectedOfficesIndexes = this.offices
				.map((o, i) => (selectedOffices.includes(o.key) ? i : null))
				.filter(o => o !== null);

			this.setSelectedOffices(selectedOffices);
		},
		async handleGetConfig() {
			const response = await getConfig();

			if (response.status === 200) {
				this.setUser(response.data.user);
				this.setDarkMode(response.data.user.darkMode);
				this.$vuetify.theme.dark = response.data.user.darkMode;
				this.setHorizontalMenu(response.data.user.horizontalMenu);
				this.setProfiles(response.data.user.profiles);
				this.setOffices(response.data.config.offices);
				this.setFeatures(response.data.accessMap.features);
				this.setRedirect(response.data.accessMap.redirect);
				if (response.data.oldestDate) this.setOldestDate(response.data.oldestDate);
				this.setConfig(response.data.config);

				document.title = response.data.accessMap.name;

				this.setTopBarWidths();
			} else {
				logout();
			}
		},
	},
});
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.navigationDrawer {
	position: sticky;
	top: 48px;
	z-index: 1000;
}

.tab {
	font-size: 0.85em;
}

@media only screen and (max-width: 1050px) {
	.navigationDrawer {
		top: 48px;
	}
}

.widgetCard {
	height: 100%;
	padding: 10px;
}

.flexContainer {
	min-width: 100%;
	display: flex;
	justify-content: center;
}

.table {
	padding: 0px;
	transition: width 500ms ease-in-out;
	overflow: auto;
}

@media only screen and (max-width: 1100px) {
	.flexContainer.withFilters {
		flex-wrap: wrap;
	}

	.showFilters {
		padding-bottom: 10px;
		opacity: 1;
		transition: opacity 250ms ease-in-out;
	}

	.hideFilters {
		opacity: 0;
		min-width: 0px;
		max-width: 0px;
	}
}

@media only screen and (min-width: 1100px) {
	.showFilters {
		padding-right: 10px;
		opacity: 1;
		min-width: 415px;
		max-width: 415px;
		transition: min-width 250ms ease-in-out, max-width 250ms ease-in-out, opacity 250ms ease-in-out 250ms;
	}

	.hideFilters {
		opacity: 0;
		min-width: 0px;
		max-width: 0px;
		transition: min-width 250ms ease-in-out 250ms, max-width 250ms ease-in-out 250ms, opacity 250ms ease-in-out,
			padding-right 0ms 250ms;
	}
}
</style>
