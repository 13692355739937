var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center","gap":"10px"}},[_c('div',[_c('v-btn-toggle',{staticStyle:{"margin-bottom":"10px"},attrs:{"mandatory":""},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}},[_c('v-btn',{attrs:{"value":"consultant"}},[_vm._v("Individual")]),_c('v-btn',{attrs:{"value":"team"}},[_vm._v("Equipas")])],1),_c('v-btn-toggle',{staticStyle:{"margin-left":"10px"},attrs:{"mandatory":""},model:{value:(_vm.fromAllOffices),callback:function ($$v) {_vm.fromAllOffices=$$v},expression:"fromAllOffices"}},[_c('v-btn',{attrs:{"value":false}},[_vm._v("Agência")]),_c('v-btn',{attrs:{"value":true}},[_vm._v("Grupo")])],1)],1),(_vm.loading)?_c('div',{style:(`display: flex; gap: 10px; justify-content: center; margin:${150}px`)},_vm._l((_vm.cards),function(card){return _c('v-skeleton-loader',{key:card.stat,attrs:{"min-width":"450px","type":"image"}})}),1):_vm._l((_vm.periods.slice(0, -1)),function(period){return _c('div',{key:period.key},[_c('h2',[_vm._v(_vm._s(period.name))]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"10px"}},_vm._l((_vm.cards),function(card){return _c('div',{key:card.stat},[(_vm.statsByType[period.key])?_c('v-data-table',{attrs:{"headers":[
						{ text: 'Nome', value: 'name', width: 170 },
						{
							text: `${card.name}${card.stat === 'findings' ? '' : ' (Diferencial em %)'}`,
							value: 'stat',
							width: 190,
							align: 'right',
						},
					],"items":_vm.statsByType[period.key][card.stat],"loading":_vm.loading,"item-key":"_id","must-sort":"","no-data-text":"Não há resultados","no-results-text":"Não há resultados","footer-props":{
						itemsPerPageOptions: [10, 20],
						itemsPerPageText: 'Linhas',
						showCurrentPage: true,
						showFirstLastPage: true,
					}},scopedSlots:_vm._u([{key:"item.stat",fn:function({ item }){return [_vm._v(" "+_vm._s(card.stat === "findings" ? item[period.key][card.stat] : _vm.features.listBillingStats ? _vm.formatNumber(item[period.key][card.stat], true) : item[period.key][`${card.stat}ComparedToRankAbovePercent`] ? `${_vm.formatNumber(item[period.key][`${card.stat}ComparedToRankAbovePercent`])}%` : "")+" ")]}}],null,true)}):_vm._e()],1)}),0)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }