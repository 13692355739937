<template>
	<div>
		<v-btn-toggle v-if="isDashboard" mandatory v-model="tab" style="margin-bottom: 10px">
			<v-btn value="stats">Estatísticas</v-btn>
			<v-btn value="rankings">Rankings</v-btn>
		</v-btn-toggle>
		<div
			v-if="loading"
			:style="`display: flex; gap: 10px; justify-content: center; margin:${isDashboard ? 0 : 150}px`"
		>
			<v-skeleton-loader v-for="card in cards" :key="card.stat" min-width="450px" type="image" />
		</div>
		<div v-else-if="stats">
			<span v-if="!isDashboard" style="display: flex; gap: 10px">
				<h2>{{ stats.name }}</h2>
				<v-chip small v-for="office in stats.offices" :key="office.key" style="margin: 5px 5px 0px 0px">
					{{ allOffices.find(o => o.key === office).name }}
				</v-chip>
			</span>
			<div v-if="!isDashboard" style="display: flex; flex-direction: column; text-align: left; width: fit-content">
				<span>
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								{{ formatDate(stats.startDate, "DD-MM-YYYY") }}
							</span>
						</template>
						<span>Data de Entrada</span>
					</v-tooltip>
					<v-tooltip v-if="stats.endDate" right>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on"> - {{ formatDate(stats.endDate, "DD-MM-YYYY") }} </span>
						</template>
						<span>Data de Saída</span>
					</v-tooltip>
				</span>
				<v-tooltip right>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ stats.manager.name }}
						</span>
					</template>
					<span>Responsável</span>
				</v-tooltip>
			</div>
			<v-btn-toggle v-if="!isDashboard" v-model="tab" mandatory style="margin-bottom: 10px">
				<v-btn value="stats">Estatísticas</v-btn>
				<v-btn v-if="features.listViabilities" value="viabilities">Viabilidades</v-btn>
				<v-btn v-if="features.listFindings" value="findings">Angariações</v-btn>
				<v-btn v-if="features.listReservations" value="reservations">Pipeline</v-btn>
				<v-btn v-if="features.listInvoices" value="invoices">Faturas</v-btn>
			</v-btn-toggle>
			<div v-if="tab === 'stats'" style="display: flex; flex-direction: column; gap: 10px">
				<div style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px">
					<v-card v-for="card in cards" :key="card.stat" min-width="450px">
						<v-card-text
							style="
								padding: 10px;
								text-align: left;
								font-size: 1.5em;
								display: flex;
								flex-direction: column;
								gap: 10px;
								height: 100%;
							"
						>
							<div v-for="period in periods" :key="period.key">
								<div
									:style="`background-color: ${
										$vuetify.theme.dark ? '#333' : '#f2f2f2'
									}; border-radius: 5px; padding: 5px`"
								>
									<v-chip style="border-radius: 3px">{{ period.name }}</v-chip>
									<v-tooltip top color="#222">
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												{{
													formatNumber(
														stats?.[period.key]?.[card.stat] || 0,
														card.stat !== "findings",
														false,
														card.stat === "findings" ? 0 : 2,
														true,
													)
												}}
											</span>
										</template>
										<span>Realizado</span>
									</v-tooltip>
									<span
										v-if="card.stat === 'findings' && stats?.[period.key]?.findingsValue"
										style="font-size: 0.65em"
									>
										{{ `(${formatNumber(stats?.[period.key]?.findingsValue, true)})` }}
									</span>
									<v-tooltip v-if="stats?.[period.key]?.[`${card.stat}Goal`]" top color="#222">
										<template v-slot:activator="{ on, attrs }">
											<b v-bind="attrs" v-on="on" style="font-size: 0.6em; margin-right: 5px">
												/
												{{
													formatNumber(
														stats[period.key][`${card.stat}Goal`],
														card.stat !== "findings",
														false,
														card.stat === "findings" ? 0 : 2,
													)
												}}
											</b>
										</template>
										<span>Objetivo</span>
									</v-tooltip>
									<span v-if="period.key !== 'period'" style="display: flex; gap: 5px; float: right; margin: 5px">
										<PercentageChip
											v-if="period.key.includes('-')"
											:stats="stats[period.key]"
											:stat="`${card.stat}YearlyGrowth`"
											tooltip="Comparado ao mesmo mês do ano anterior"
											:type="type"
											:chip="true"
											:onClick="changeStatsType"
										/>
										<PercentageChip
											:stats="stats[period.key]"
											:stat="`${card.stat}PeriodicGrowth`"
											:tooltip="`Comparado ao ${period.key.includes('-') ? 'mês' : 'ano'} anterior`"
											:type="type"
											:chip="true"
											:onClick="changeStatsType"
										/>
									</span>
									<div
										v-if="stats?.[period.key]?.[`${card.stat}Goal`]"
										style="margin-top: 10px; font-size: 0.65em"
									>
										<v-progress-linear rounded :value="stats[period.key][`${card.stat}GoalPercent`]" height="12">
											<template v-slot:default="{ value }">
												<span style="font-size: 0.75em">{{ Math.round(value) }}%</span>
											</template>
										</v-progress-linear>
										<div style="display: flex; justify-content: space-between; margin-top: 5px">
											<span v-if="period.key !== 'period'" style="display: flex; gap: 15px">
												<v-tooltip top color="#222">
													<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on">
															{{
																formatNumber(
																	stats[period.key][`${card.stat}Expected`],
																	card.stat !== "findings",
																	false,
																	card.stat === "findings" ? 0 : 2,
																)
															}}
														</span>
													</template>
													<span>Esperado</span>
												</v-tooltip>
												<PercentageChip
													:stats="stats[period.key]"
													:stat="`${card.stat}ComparedToExpected`"
													tooltip="Comparado ao esperado"
													:type="type"
													:onClick="e => changeStatsType(e, true)"
												/>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div style="height: 100%">
								<div
									v-if="card.stat === 'reservations'"
									:style="`background-color: ${
										$vuetify.theme.dark ? '#333' : '#f2f2f2'
									}; border-radius: 5px; padding: 5px`"
								>
									<v-chip style="border-radius: 3px">Por Executar</v-chip>
									{{ formatNumber(stats?.pipeline || 0, true, false, 2, true) }}
								</div>
							</div>
							<div
								style="
									display: flex;
									justify-content: space-between;
									align-items: center;
									margin-top: 10px;
									flex-grow: 1;
								"
							>
								{{ card.name }}
								<v-icon v-if="card.icon" color="grey" style="font-size: 1.5em">
									{{ card.icon }}
								</v-icon>
							</div>
						</v-card-text>
					</v-card>
				</div>
				<div v-if="stats[year]" style="display: flex; flex-direction: column; gap: 10px">
					<div style="display: flex; gap: 10px">
						<v-select
							outlined
							dense
							v-model="stat"
							:items="statTypes"
							item-text="name"
							item-value="value"
							label="Estatística"
							hide-details
							style="min-width: 160px; max-width: 160px"
						/>
						<v-text-field
							outlined
							dense
							v-model="numberOfYears"
							type="number"
							label="Nº de Anos"
							hide-details
							style="min-width: 160px; max-width: 160px"
						/>
					</div>
					<div style="display: flex; gap: 10px">
						<v-card class="widgetCard" width="100%">
							<LineChart
								width="100%"
								height="600px"
								title="Comparação da faturação acumulada"
								:seriesOverwrite="accumulatedStats"
								:labels="accumulatedLabels"
								:colors="[
									'#2E93fA',
									'#2E93fA',
									'#FF9800',
									'#FF9800',
									'#E91E63',
									'#E91E63',
									'#00BCD4',
									'#00BCD4',
									'#9C27B0',
									'#9C27B0',
									'#3F51B5',
									'#3F51B5',
									'#546E7A',
									'#546E7A',
									'#FFC107',
									'#FFC107',
								]"
								:opacity="[0.25, 1, 0.25, 1]"
								:yFormatter="stat === 'findings' ? number => formatNumber(number, false, false, 0) : formatNumber"
							/>
						</v-card>
						<ComparisonBarChart
							group="consultant"
							:_id="id"
							:statOverwrite="stat"
							:statsOverwrite="stats"
							:numberOfYears="numberOfYears"
						/>
					</div>
					<div style="display: flex; gap: 10px; align-items: center">
						<v-card class="widgetCard" width="100%">
							<LineChart
								width="100%"
								height="353px"
								title="Comparação da percentagem do total"
								:seriesOverwrite="percentOfTotalStats"
								:labels="accumulatedLabels"
								:yFormatter="v => `${v ? v.toFixed(1) : 0}%`"
							/>
						</v-card>
						<v-card class="widgetCard" width="100%">
							<LineChart
								width="100%"
								height="353px"
								title="Comparação em relação ao mesmo mês do ano anterior"
								:seriesOverwrite="growthStats"
								:labels="accumulatedLabels"
								:yFormatter="v => `${v ? v.toFixed(1) : 0}%`"
							/>
						</v-card>
						<v-card v-if="stat === 'billing'" class="widgetCard" width="100%">
							<PieChart title="Angariador/Vendedor" :stats="finderSellerStats" />
						</v-card>
					</div>
					<div style="display: flex; gap: 10px; align-items: center">
						<v-card class="widgetCard" width="100%">
							<LineChart
								width="100%"
								height="353px"
								title="Comparação em relação à mediana"
								:seriesOverwrite="medianStats"
								:labels="accumulatedLabels"
								:opacity="0.25"
								:yFormatter="v => `${v ? v.toFixed(1) : 0}%`"
							/>
						</v-card>
					</div>
				</div>
			</div>
			<RankingTables v-if="tab === 'rankings'" />
			<Viabilities v-if="tab === 'viabilities'" :profileOverwrite="profile" />
			<Findings v-if="tab === 'findings'" :profileOverwrite="profile" />
			<Reservations v-if="tab === 'reservations'" :profileOverwrite="profile" />
			<Invoices v-if="tab === 'invoices'" :profileOverwrite="profile" />
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import Invoices from "./Invoices";
import Reservations from "./Reservations";
import Findings from "./Findings";
import Viabilities from "./Viabilities";

import LineChart from "../components/dashboard/LineChart";
import ComparisonBarChart from "../components/dashboard/ComparisonBarChart";
import PieChart from "../components/dashboard/PieChart";
import PercentageChip from "../components/dashboard/PercentageChip";
import RankingTables from "../components/dashboard/RankingTables";

import { getGroupedStats } from "../api/stats";

import { formatNumber, formatDate } from "../utils/utils";

export default Vue.extend({
	name: "StaffStats",
	components: {
		Invoices,
		Reservations,
		Findings,
		Viabilities,
		LineChart,
		ComparisonBarChart,
		PieChart,
		PercentageChip,
		RankingTables,
	},
	created() {
		this.isDashboard = !this.$route.params.id;
		this.id = this.$route.params.id ? this.$route.params.id : this.user._id;
	},
	watch: {
		"$route.params.id": function () {
			this.handleGetStats();
		},
		dateRange: function () {
			this.formatStats();
		},
		stat: function () {
			this.formatStats();
		},
		numberOfYears: function () {
			this.formatStats();
		},
		allOffices: function () {
			this.handleGetStats();
		},
	},
	computed: mapState(["allOffices", "dateRange", "user", "features"]),
	data() {
		return {
			loading: false,

			isDashboard: false,

			id: null,
			stats: null,

			accumulatedStats: [],
			accumulatedLabels: [],
			percentOfTotalStats: [],
			growthStats: [],
			medianStats: [],
			finderSellerStats: [],

			stat: "billing",
			statTypes: [
				{ name: "Faturação", value: "billing" },
				{ name: "Pago", value: "billingPaid" },
				{ name: "Previsto", value: "predicted" },
				{ name: "Pipeline", value: "reservations" },
				{ name: "Angariações", value: "findings" },
				{ name: "Valor de Angariações", value: "findingsValue" },
			],
			numberOfYears: 2,
			type: 0,

			cards: [
				{ name: "Angariações", stat: "findings", icon: "mdi-home-automation" },
				{ name: "Pipeline", stat: "reservations", icon: "mdi-bookmark" },
				{ name: "Faturação", stat: "billing", icon: "mdi-file-check" },
			],
			periods: [],

			allStats: [],
			statsByType: {},

			tab: "stats",
			profile: { filters: { active: true } },
		};
	},
	methods: {
		formatNumber,
		formatDate,
		changeStatsType(e, skipLast) {
			this.type = this.type === 2 || (this.type === 1 && skipLast) ? 0 : this.type + 1;
		},
		async handleGetStats() {
			this.loading = true;

			this.profile.filters.consultants = [this.id];

			const response = await getGroupedStats("consultant", this.stat, null, null, null, "!team");

			if (response.status === 200) {
				this.allStats = response.data;
				this.stats = response.data[this.id];

				this.formatStats();
			} else {
				this.$router.replace("/users");
			}

			this.loading = false;
		},
		async formatStats() {
			this.accumulatedStats = [];
			this.percentOfTotalStats = [];
			this.growthStats = [];
			this.medianStats = [];
			this.finderSellerStats = [];

			const labels = [];

			this.today = dayjs(this.dateRange[1]);
			this.year = this.today.get("year");

			this.periods = [
				{ name: formatDate(this.today, "MMM YYYY"), key: `${this.today.get("month") + 1}-${this.year}` },
				{ name: this.year, key: this.year.toString() },
			];

			if (this.year === dayjs(this.dateRange[0]).get("year")) {
				this.periods.push({ name: "Intervalo selecionado", key: "period" });
			}

			const years = [];
			for (let i = dayjs(this.dateRange[0]).get("year"); i <= this.year; i++) {
				years.push(i);
			}

			while (years.length < Number(this.numberOfYears)) {
				years.unshift(years[0] - 1);
			}

			for (const year of years) {
				const stats = [];
				const accumulatedStats = [];
				const percentOfTotalStats = [];
				const growthStats = [];
				const medianStats = [];
				let accumulated = 0;

				for (let j = 1; j <= 12; j++) {
					if (labels.length < 12) labels.push(formatDate(`${year}-${j}-1`, "MMM"));

					const monthStats = this.stats?.[`${j}-${year}`];
					const stat = monthStats ? monthStats[this.stat] : 0;
					accumulated += stat;

					stats.push(stat);
					accumulatedStats.push(accumulated);
					percentOfTotalStats.push(
						monthStats && monthStats[`${this.stat}PercentOfTotal`] ? monthStats[`${this.stat}PercentOfTotal`] : 0,
					);
					growthStats.push(
						monthStats && monthStats[`${this.stat}YearlyGrowth`]
							? monthStats[`${this.stat}YearlyGrowthPercent`]
							: 0,
					);
					medianStats.push(
						monthStats && monthStats[`${this.stat}ComparedToMedian`]
							? monthStats[`${this.stat}ComparedToMedianPercent`]
							: 0,
					);
				}

				this.accumulatedStats.push({ name: `${year} - Acumulado`, type: "area", data: accumulatedStats });
				this.accumulatedStats.push({ name: `${year} - Mês`, type: "bar", data: stats });
				this.percentOfTotalStats.push({ name: year, type: "line", data: percentOfTotalStats });
				this.growthStats.push({ name: year, type: "line", data: growthStats });
				this.medianStats.push({ name: year, type: "area", data: medianStats });
			}

			this.accumulatedLabels = labels;

			this.finderSellerStats = [
				{ name: "Vendedor", total: this.stats?.[this.year]?.[`${this.stat}Seller`] },
				{ name: "Angariador", total: this.stats?.[this.year]?.[`${this.stat}Finder`] },
			];

			this.statsByType = {};
			for (const period of this.periods.slice(0, -1)) {
				this.statsByType[period.key] = {};

				for (const card of this.cards) {
					let sortedStats = [];

					for (const id in this.allStats) {
						if (id !== "total" && this.allStats[id] && this.allStats[id][period.key]) {
							sortedStats.push(this.allStats[id]);
						}
					}

					sortedStats.sort(
						(a, b) =>
							(b[period.key] ? b[period.key][card.stat] || 0 : 0) -
							(a[period.key] ? a[period.key][card.stat] || 0 : 0),
					);

					sortedStats = sortedStats.filter(s => !s.team);

					this.statsByType[period.key][card.stat] = sortedStats;

					for (let i = 0; i < this.statsByType[period.key][card.stat].length; i++) {
						const consultant = this.statsByType[period.key][card.stat][i];
						const previousConsultant = i > 0 ? this.statsByType[period.key][card.stat][i - 1] : null;

						if (consultant[period.key] && consultant[period.key][card.stat] && previousConsultant) {
							consultant[period.key][`${card.stat}ComparedToRankAbove`] =
								consultant[period.key][card.stat] - previousConsultant[period.key][card.stat];
							consultant[period.key][`${card.stat}ComparedToRankAbovePercent`] =
								(consultant[period.key][`${card.stat}ComparedToRankAbove`] / consultant[period.key][card.stat]) *
								100;
						}
					}
				}
			}
		},
	},
});
</script>
