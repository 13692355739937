import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";

import { logout } from "../api/auth";

Vue.use(VueRouter);

function redirect(to, next) {
	const token = localStorage.getItem("token");
	const features = JSON.parse(localStorage.getItem("features"));

	if (!token) return next();

	if (to.name === "Invoices" && !features.listInvoices) return next({ name: "Home" });
	if (to.name === "Reservations" && !features.listReservations) return next({ name: "Home" });
	if (to.name === "Findings" && !features.listFindings) return next({ name: "Home" });
	if (to.name === "Viabilities" && !features.listViabilities) return next({ name: "Home" });
	if (to.name === "Expenses" && !features.listExpenses) return next({ name: "Home" });
	if (["Leads", "LeadDetail"].includes(to.name) && !features.listLeads) return next({ name: "Home" });
	if (["Staff", "StaffDetail"].includes(to.name) && !features.listConsultants && !features.listStaff) {
		return next({ name: "Home" });
	}
	if (to.name === "Config" && !features.listConfigs) return next({ name: "Home" });

	next();
}

const routes = [
	{
		path: "/",
		alias: ["/forgot-password"],
		name: "Home",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: Home,
	},
	{
		path: "/profile",
		name: "UserProfile",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-staff" */ "../views/UserProfile.vue"),
	},
	{
		path: "/users",
		name: "Staff",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-staff" */ "../views/Staff.vue"),
	},
	{
		path: "/users/image-generator",
		name: "ImageGenerator",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-staff" */ "../views/ImageGenerator.vue"),
	},
	{
		path: "/users/:id",
		name: "StaffDetail",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-staff" */ "../views/StaffDetail.vue"),
	},
	{
		path: "/users/:id/stats",
		name: "StaffStats",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-staff" */ "../views/StaffStats.vue"),
	},
	{
		path: "/leads",
		name: "Leads",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-leads" */ "../views/Leads.vue"),
	},
	{
		path: "/leads/:id",
		name: "LeadDetail",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-leads" */ "../views/LeadDetail.vue"),
	},
	{
		path: "/viabilities",
		name: "Viabilities",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-viabilities" */ "../views/Viabilities.vue"),
	},
	{
		path: "/findings",
		name: "Findings",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-findings" */ "../views/Findings.vue"),
	},
	{
		path: "/reservations",
		name: "Reservations",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-reservations" */ "../views/Reservations.vue"),
	},
	{
		path: "/reservations/:id",
		name: "ReservationDetail",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-reservations" */ "../views/ReservationDetail.vue"),
	},
	{
		path: "/invoices",
		name: "Invoices",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-invoices" */ "../views/Invoices.vue"),
	},
	{
		path: "/invoices/:id",
		name: "InvoiceDetail",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-invoices" */ "../views/InvoiceDetail.vue"),
	},
	{
		path: "/expenses",
		name: "Expenses",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-expenses" */ "../views/Expenses.vue"),
	},
	{
		path: "/config",
		name: "Config",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Config.vue"),
	},
	{
		path: "/config/access",
		name: "Access",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Access.vue"),
	},
	{
		path: "/config/lists",
		name: "Lists",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Lists.vue"),
	},
	{
		path: "/config/emails",
		name: "Emails",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Emails.vue"),
	},
	{
		path: "/config/notifications",
		name: "Notifications",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Notifications.vue"),
	},
	{
		path: "/config/errors",
		name: "Errors",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-config" */ "../views/Errors.vue"),
	},
	{
		path: "/gmail",
		name: "Gmail",
		beforeEnter: (to, from, next) => redirect(to, next),
		component: () => import(/* webpackChunkName: "group-gmail" */ "../views/Gmail.vue"),
	},
	{
		path: "/logout",
		beforeEnter: () => logout(),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
